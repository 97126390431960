/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import logoImg from "../assets/images/logo_black.png";
import "./less/home.less";
import { Link, withRouter } from "react-router-dom";
import { Modal, Radio, Button, notification } from "antd";
import { radioStyle, itemList } from "./data.source";
import axios from "axios";
import { PublicKey } from "@solana/web3.js";
import { Application } from "@splinetool/runtime";

const bs58 = require("bs58");

const qrcode1 = require("../assets/images/presale/Android.png");
const qrcode2 = require("../assets/images/presale/AppleQR.png");

SwiperCore.use([Autoplay, Navigation, Pagination]);

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const validateSolAddress = (address) => {
  try {
    let pubkey = new PublicKey(address);
    let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
    return isSolana;
  } catch (error) {
    return false;
  }
};

const getProvider = () => {
  if ("phantom" in window) {
    const provider = window.phantom.solana;
    if (provider.isPhantom) {
      return provider;
    }
  }
  window.open("https://phantom.app/", "_blank");
};

const getOkxProvider = () => {
  if (typeof window.okxwallet !== "undefined") {
    console.log("OKX is installed!");
    return window.okxwallet.solana;
  }
};

let isProd = process.env.NODE_ENV === "production";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      isPlayed: 0,
      isAnimationLoaded: true,
      openPhaseIndex: [0],
      registed: false,
      canReg: false,
      firstReg: false,
      estimatedRank: 0,
      registInfo: {},
      solAddress: "",
      checkPass: false,
      // deviceNum: 0
    };
  }
  componentDidMount = () => {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic3RhcnBvd2VyMTAwMCIsImEiOiJjbHF6NWF6amkwMXFrMnJwcWxsMmtzNnpqIn0.ZKvlgyGdlaFmviObw2sUvg";
    const map = new mapboxgl.Map({
      container: "homeStarpowerMap", // container ID
      style: "mapbox://styles/starpower1000/cm2kggs7b009401qsgdvz93hx",
      center: [-74.5, 40], // starting position [lng, lat]
      zoom: isMobile ? 1 : 1.5, // starting zoom
      minZoom: isMobile ? 0.75 : 1.5,
      minPitch: 0,
      maxZoom: isMobile ? 0.75 : 1.5,
      maxPitch: 0,
      bearing: 0,
      pitch: 0,
      dragRotate: true,
      logo: false,
      scrollZoom: false,
    });
    map.on("load", () => {
      map.addLayer({
        maxWidth: 1400,
      });
    });
    let earthRoate = setInterval(function () {
      var center = map.getCenter();
      map.easeTo({
        center: [center.lng + 10, center.lat],
        duration: 1000,
      });
    }, 100);
    map.on("mousedown", () => {
      clearInterval(earthRoate);
    });
    map.on("mouseup", () => {
      earthRoate = setInterval(function () {
        var center = map.getCenter();
        map.easeTo({
          center: [center.lng + 10, center.lat],
          duration: 1000,
        });
      }, 100);
    });
    // axios.get('/starpower/manage/api/statics/selectDevicesCount').then((res) => {
    //   console.log(res.data);
    //   this.setState({
    //     deviceNum: res.data.data
    //   })
    // });
    window.addEventListener('resize', function() {
      map.resize();
  });
    window.scrollTo(0, 0);

    this.bindHandleScroll();
    window.addEventListener("scroll", this.bindHandleScroll);
    if (isMobile) {
      this.loadVideo();
    } else {
      this.loadVideo();
      this.loadSpline();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.bindHandleScroll);
  }

  bindHandleScroll = (event) => {
    this.checkScrollHeightAndLoadAnimation(".content1-title", 170, [
      "fadeIn",
      "cube",
    ]);
    this.checkScrollHeightAndLoadAnimation(".content1-desc", 270, [
      "fadeIn",
      "cube",
    ]);
    this.checkScrollHeightAndLoadAnimation(".presale-btn-button", 270, [
      "fadeIn",
      "cube",
    ]);
    this.checkScrollHeightAndLoadAnimation(
      ".homepage-floor2",
      570,
      ["fadeIn"],
      "heightHalf"
    );
    // this.checkScrollHeightAndLoadAnimation('.content1-stat',790,['fadeIn','cube']);

    this.checkScrollHeightAndLoadAnimation(
      ".content1-stat-item1",
      0,
      ["statistics"],
      "top"
    );
    this.checkScrollHeightAndLoadAnimation(
      ".content1-stat-item2",
      0,
      ["statistics", "statistics2"],
      "top"
    );
    this.checkScrollHeightAndLoadAnimation(
      ".content1-stat-item3",
      0,
      ["statistics", "statistics3"],
      "top"
    );
    this.checkScrollHeightAndLoadAnimation(".content1-stat-item4", 0, [
      "statistics",
      "statistics4",
    ],"top");
    this.checkScrollHeightAndLoadAnimation(".content1-stat-item5", 0, [
      "statistics",
      "statistics5",
    ],"top");

    this.checkScrollHeightAndLoadAnimation(".content2-title", 0, ["shiftLeft"]);
    this.checkScrollHeightAndLoadAnimation(".content2-desc", 0, ["shiftLeft"]);

    this.checkScrollHeightAndLoadAnimation(".circle-container", 0, [
      "shiftLeft",
    ]);

    this.checkScrollHeightAndLoadAnimation(".phase-container-item-1", 0, [
      "shiftLeft",
    ]);
    this.checkScrollHeightAndLoadAnimation(".phase-container-item-2", 0, [
      "shiftLeft",
      "shiftLeft2",
    ]);
    this.checkScrollHeightAndLoadAnimation(".phase-container-item-3", 0, [
      "shiftLeft",
      "shiftLeft3",
    ]);
    this.checkScrollHeightAndLoadAnimation(
      ".phase-container-item-content1",
      0,
      ["shiftLeft"]
    );
    this.checkScrollHeightAndLoadAnimation(
      ".phase-container-item-content2",
      0,
      ["shiftLeft", "shiftLeft2"]
    );
    this.checkScrollHeightAndLoadAnimation(
      ".phase-container-item-content3",
      0,
      ["shiftLeft", "shiftLeft3"]
    );

    this.checkScrollHeightAndLoadAnimation(".content4-title", 0, [
      "brandTitle",
    ]);
    if (!isMobile) {
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item1", 0, [
        "brand",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item2", 0, [
        "brand",
        "brand2",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item3", 0, [
        "brand",
        "brand3",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item4", 0, [
        "brand",
        "brand4",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item5", 0, [
        "brand",
        "brand5",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item11", 0, [
        "brand",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item22", 0, [
        "brand",
        "brand2",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item33", 0, [
        "brand",
        "brand3",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item44", 0, [
        "brand",
        "brand4",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item55", 0, [
        "brand",
        "brand5",
      ]);
      // this.checkScrollHeightAndLoadAnimation(
      //   ".brand-container-row-item111",
      //   0,
      //   ["brand"]
      // );
      // this.checkScrollHeightAndLoadAnimation(
      //   ".brand-container-row-item222",
      //   0,
      //   ["brand", "brand2"]
      // );
      // this.checkScrollHeightAndLoadAnimation(
      //   ".brand-container-row-item333",
      //   0,
      //   ["brand", "brand3"]
      // );
    } else {
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item1", 0, [
        "brand",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item2", 0, [
        "brand",
        "brand2",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item3", 0, [
        "brand",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item11", 0, [
        "brand",
        "brand2",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item22", 0, [
        "brand",
      ]);
      this.checkScrollHeightAndLoadAnimation(".brand-container-row-item33", 0, [
        "brand",
        "brand2",
      ]);
      this.checkScrollHeightAndLoadAnimation(
        ".brand-container-row-item111",
        0,
        ["brand"]
      );
      this.checkScrollHeightAndLoadAnimation(
        ".brand-container-row-item222",
        0,
        ["brand", "brand2"]
      );
      this.checkScrollHeightAndLoadAnimation(
        ".brand-container-row-item333",
        0,
        ["brand"]
      );
      this.checkScrollHeightAndLoadAnimation(
        ".brand-container-row-item444",
        0,
        ["brand", "brand2"]
      );
    }
  };

  checkScrollHeightAndLoadAnimation(
    activeSelector,
    scrollY,
    activeClassName,
    position
  ) {
    let activeEle = document.querySelectorAll(activeSelector);
    if (activeEle.length === 0) return;
    // if (window.scrollY > scrollY) {
    if (position == "top") {
      // 顶部出现在可视区域内 动画展示
      if (this.isElementInViewportTop(activeEle[0])) {
        activeEle.forEach((item) => {
          item.classList.add(...activeClassName);
        });
      } else {
        activeEle.forEach((item) => {
          item.classList.remove(...activeClassName);
        });
      }
    } else if (position == "heightHalf") {
      // 元素高度一半出现在可视区域内 动画展示
      if (this.isElementInViewportHeightHalf(activeEle[0])) {
        activeEle.forEach((item) => {
          item.classList.add(...activeClassName);
        });
      } else {
        activeEle.forEach((item) => {
          item.classList.remove(...activeClassName);
        });
      }
    } else {
      // 元素底部出现在可视区域内 动画展示
      if (this.isElementInViewport(activeEle[0])) {
        activeEle.forEach((item) => {
          item.classList.add(...activeClassName);
        });
      } else {
        activeEle.forEach((item) => {
          item.classList.remove(...activeClassName);
        });
      }
    }
  }

  isElementInViewport(el) {
    // ‌x, y‌: 元素左上角相对于视口的坐标。
    // ‌width, height‌: 元素的宽度和高度。
    // ‌top, right, bottom, left‌: 分别表示元素的上、右、下、左边界相对于视口的距离。
    // console.log('el',el)
    var rect = el.getBoundingClientRect();
    // console.log('rect',rect)
    // 只有从下滚动的时候，才会显示css动画，所有rect.top>=0 删除即可
    return (
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  isElementInViewportTop(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  isElementInViewportHeightHalf(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.bottom - rect.height / 2 <=
      (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  // 加载Spline动画
  loadSpline = () => {
    if (isMobile) return;
    let canvas = document.getElementById("canvas");
    let spline = new Application(canvas);
    let url = !isMobile
      ? "https://prod.spline.design/uXWY1GHotf3me7MA/scene.splinecode"
      : "https://prod.spline.design/2RD0KIXACplRGvQ9/scene.splinecode";
    isProd && spline.load(url);
    // let canvas2 = document.getElementById("canvas2");
    // let spline2 = new Application(canvas2);
    // let url2 = "https://prod.spline.design/X-Ggcjmtfm-oNjWD/scene.splinecode";
    // isProd && spline2.load(url2);
  };

  loadVideo = () => {
    let myVideo1 = document.getElementById("jzVideo1");
    if (isMobile) {
      let myVideoFirst = document.getElementById("jzVideo-first");
      setTimeout(() => {
        myVideoFirst.play();
        myVideo1.play();
      }, 500);
      myVideoFirst.addEventListener(
        "ended",
        function () {
          myVideoFirst.currentTime = 0.1;
          myVideoFirst.play();
        },
        false
      );
      myVideo1.addEventListener(
        "ended",
        function () {
          myVideo1.currentTime = 0.1;
          myVideo1.play();
        },
        false
      );
    } else {
      myVideo1.addEventListener("canplay", function (e) {
        myVideo1.play();
      });
      myVideo1.addEventListener(
        "ended",
        function () {
          myVideo1.currentTime = 0.1;
          myVideo1.play();
        },
        false
      );
    }
  };

  openPhase = (index) => {
    this.setState({
      openPhaseIndex: [index],
    });
  };
  getEstimatedRank = async (addr) => {
    let params = {
      solAddress: addr,
    };
    await axios({
      url: "/refer/api/estimatedRank",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          estimatedRank: res.data.data,
        });
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getRefInfo = async () => {
    let addr =
      this.state.solAddress ||
      localStorage.getItem("solanaRegAddress") ||
      localStorage.getItem("solanaAddress");
    await axios.get("/refer/api/referInfo?solAddress=" + addr).then((res) => {
      this.setState({
        registInfo: res.data.data,
      });
    });
  };
  checkAddr = async () => {
    let addr = document.getElementById("walletAddress").value;
    let isSolAddress = validateSolAddress(addr);
    if (isSolAddress) {
      axios({
        url: "/refer/api/checkIfExist?solanaAddress=" + addr,
        method: "get",
        headers: {
          "Content-Type": "multipart/form-data", //Content-Type form
          //'Content-Type': 'application/json'
        },
      }).then((res) => {
        localStorage.setItem("solanaRegAddress", addr);
        localStorage.setItem("notConnectWallet", true);
        if (res.data.data) {
          this.setState({
            registed: true,
            canReg: true,
            checkPass: true,
          });
        } else {
          this.setState({
            registed: false,
            canReg: true,
            checkPass: false,
          });
        }
        console.log(res.data.data);
      });
    }
  };
  registWallet = async () => {
    let addr = document.getElementById("walletAddress").value;
    let isSolAddress = validateSolAddress(addr);
    if (isSolAddress) {
      console.log("The address is valid");
      // this.getBalance(addr);
      axios({
        url: "/refer/api/register?solanaAddress=" + addr,
        method: "get",
        headers: {
          // "Content-Type": "multipart/form-data", //Content-Type form
          'Content-Type': 'application/json'
        },
      })
        .then((res) => {
          localStorage.setItem("solanaRegAddress", addr);
          localStorage.setItem("notConnectWallet", true);
          if (res.data.code == 200) {
            notification.success({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Succress",
              description: "Submitted successfully!",
              onClick: () => {
                console.log("close");
              },
            });
            this.setState({
              firstReg: true,
            });
            document.body.style.overflow = 'hidden';
            this.getRefInfo();
            this.getEstimatedRank(addr);
          } else if (res.data.code == 20202) {
            notification.success({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Succress",
              description: res.data.msg,
              onClick: () => {
                console.log("close");
              },
            });
            this.setState({
              firstReg: false,
            });
          } else {
            notification.error({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Error",
              description: res.data.msg,
              onClick: () => {
                console.log("close");
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      notification.error({
        top: 70,
        duration: 1.5,
        placement: "bottomLeft",
        message: "Error",
        description: "Please enter the correct solana wallet address",
        onClick: () => {
          console.log("close");
        },
      });
      console.log("The address is NOT valid");
    }
  };
  // connect wallet
  cWallet = () => {
    localStorage.setItem("walletVisible", true);
  };
  render() {
    let {
      payType,
      isAnimationLoaded,
      showSpline,
      showSpline2,
      openPhaseIndex,
    } = this.state;
    return (
      <div
        className="templates-wrapper"
        style={{ marginTop: isAnimationLoaded ? "0" : "180px" }}
      >
        <Modal
          title=""
          width={isMobile ? "90%" : "50%"}
          centered
          footer={null}
          visible={this.state.downloadVisible}
          onCancel={() => this.cancelDownload(false)}
        >
          <div className="download-modal">
            <p>Download for {this.state.downloadTitle} mobile</p>
            <img className="download-modal-logo" src={logoImg} alt="" />
            {this.state.downloadTitle == "Apple" ? (
              <img className="download-modal-qrcode" src={qrcode2} alt="" />
            ) : (
              <img className="download-modal-qrcode" src={qrcode1} alt="" />
            )}
            <p style={{ fontWeight: "bold" }}>Your new trusted companion</p>
            <p>
              Scan the QR code or search for Starpower in the App Store or
              Google Play soon.
            </p>
          </div>
        </Modal>
        <Modal
          title="Choose payment method"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(true)}
          onCancel={() => this.setModalVisible(false)}
        >
          <Radio.Group
            onChange={this.changePayType}
            value={payType}
            direction="vertical"
          >
            <Radio style={radioStyle} value={1}>
              Card Pay
            </Radio>
            <Radio style={radioStyle} value={2}>
              Solana Pay
            </Radio>
          </Radio.Group>
        </Modal>

        {/* home-page */}
        <div className="homepage-detial">
          {this.state.isAnimationLoaded && (
            <div
              style={{ width: "100%", overflowX: !isMobile ? "hidden" : "" }}
            >
              {!this.state.firstReg ? (
                <div className="wallet-container1">
                  <div className="canvas-container">
                    {isMobile ? (
                      <video
                        className="floor-video-src"
                        id="jzVideo-first"
                        muted
                        playsInline
                        x5-video-player-type="h5"
                        src={require("@/assets/video/P1-1.mp4")}
                      />
                    ) : (
                      <canvas id="canvas"></canvas>
                    )}
                  </div>
                  <div className="wallet-title">
                    <p>User On-boarding Airdrop</p>
                    <p>Share 30,000,000 STARs</p>
                  </div>
                  <div className="wallet-addrInput">
                    <input
                      id="walletAddress"
                      className="wallet-addrInput-input"
                      type="text"
                      onChange={this.checkAddr}
                      placeholder="Enter your Solana wallet address"
                    />
                    {this.state.registed ? (
                      <Link to="/wallet">
                        <button
                          className="wallet-addrInput-submit registed"
                          type="primary"
                        >
                          Go to profile
                        </button>
                      </Link>
                    ) : (
                      <button
                        className={
                          this.state.canReg
                            ? "wallet-addrInput-submit registed"
                            : "wallet-addrInput-submit"
                        }
                        type="primary"
                        onClick={this.registWallet}
                      >
                        Register
                      </button>
                    )}
                  </div>
                  {this.state.checkPass && (
                    <div className="check-pass">
                      Solana address is already registered.
                    </div>
                  )}
                  <div className="wallet-message">
                    <p>
                      Connect your wallet and use referral code to boost your
                      rank to get more rewards.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="wallet-container2">
                  <div className="wallet-card1">
                    <div className="wallet-card1-title">REGISTER SUCCEED</div>
                    <div className="wallet-card1-info">
                      <p className="card1-info-title">Your Rank Is</p>
                      <p className="card1-info-rank">
                        #
                        {this.state.registInfo
                          ? this.state.registInfo.rank
                          : "-"}
                      </p>
                      <p className="card1-info-estimated">
                        Estimated Rank After Verification: <span>#
                        {this.state.estimatedRank
                          ? this.state.estimatedRank
                          : "-"}</span>
                      </p>
                      <p className="card1-info-message">
                        Verifying your wallet ownership can boost your ranking,
                        (Simply sign, no need for transactions)
                      </p>
                    </div>
                    <div className="wallet-card1-option">
                      <Button
                        className="card1-option-btn"
                        type="primary"
                        onClick={this.cWallet}
                      >
                        Connect Wallet
                      </Button>
                      <Link to="/wallet">
                        <Button className="card1-option-skip" type="primary">
                          Skip
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.isAnimationLoaded && (
            <>
              <div className="homepage-partner" id="presalePartner"></div>
              <div className="homepage-floor homepage-floor2">
                <div className="homepage-content1">
                  <div className="content1">
                    <div>
                      <p className="content1-title">
                        A Decentralized Energy Network
                      </p>
                      <p className="content1-desc">
                      Starpower is an open protocol for decentralized energy network.
                      Similar to how Uber aggregates vehicles globally, Starpower aggregates energy devices worldwide and responds to power grid tasks.
                      Whether it is water heaters, electric vehicles, or air conditioners and energy storage batteries, all can be linked to Starpower via hardware or software to earn $STAR rewards.
                      </p>
                    </div>
                    <Link
                      className="presale-btn-button"
                      to="/docs"
                      target="_blank"
                    >
                      <button>Lite Paper</button>
                    </Link>
                  </div>
                  <div className="homepage-mapbox-mask">
                    <div
                      className="homepage-mapbox"
                      id="homeStarpowerMap"
                    ></div>
                  </div>
                  {/* <div className="content1-right">
                <img src={content1Logo} alt="" />
              </div> */}
                </div>
                <div className="content1-stat">
                  <div className="content1-stat-item content1-stat-item1">
                    <div className="content1-stat-item-num">1.5M+</div>
                    <div className="content1-stat-item-title">
                      Wallets Registered
                    </div>
                  </div>
                  <div className="content1-stat-item content1-stat-item2">
                    <div className="content1-stat-item-num">15,954</div>
                    <div className="content1-stat-item-title">
                      Devices Activated
                    </div>
                  </div>
                  <div className="content1-stat-item content1-stat-item3">
                    <div className="content1-stat-item-num">80k+</div>
                    <div className="content1-stat-item-title">APP Users</div>
                  </div>
                  <div className="content1-stat-item content1-stat-item4">
                    <div className="content1-stat-item-num">5k+</div>
                    <div className="content1-stat-item-title">
                      Daily Active Users
                    </div>
                  </div>
                  <div className="content1-stat-item content1-stat-item5">
                    <div className="content1-stat-item-num">781+</div>
                    <div className="content1-stat-item-title">Cities</div>
                  </div>
                </div>
              </div>
              <div className="homepage-floor homepage-floor3">
                <div className="homepage-content2">
                  <p className="content2-title">The Master Plan</p>
                  {/* <p className="content2-desc">
                    Starpower leverages a combination of decentralized physical
                    infrastructure networks (DePIN) and cutting-edge virtual
                    power plant (VPP) technology. Our approach centers on
                    integrating distributed energy resources (DERs) such as
                    solar panels, wind turbines, and energy storage systems, to
                    create a responsive and dynamic energy supply capable of
                    meeting the variable demands of AI technologies.
                  </p> */}
                  <div className="homepage-floor3-bg">
                    {/* <canvas id="canvas2"></canvas> */}
                    <video
                      className="floor-video-src"
                      id="jzVideo1"
                      muted
                      playsInline
                      x5-video-player-type="h5"
                      src={require("@/assets/video/masterplan-1.mp4")}
                    />
                  </div>
                  <div className="circle-container">
                    <img
                      src={require("@/assets/images/home/circle.svg")}
                      alt=""
                      className="phase-circle"
                    />
                  </div>
                  <div className="phase-container">
                    <div
                      className={`phase-container-item ${
                        openPhaseIndex.includes(0)
                          ? "phase-container-item-active"
                          : ""
                      }`}
                      onClick={() => this.openPhase(0)}
                    >
                      <div className="phase-container-item-1">
                        <div className="phase-container-item-index">
                          Stage 1
                        </div>
                        <div className="phase-container-item-title">
                          Hardware Development
                        </div>
                      </div>
                      <div className="phase-container-item-content phase-container-item-content1">
                        <div>
                          Focus on developing high-quality hardware products,
                          covering from smart plugs to energy storage batteries
                          and home EV chargers.
                        </div>
                      </div>
                    </div>
                    <div
                      className={`phase-container-item ${
                        openPhaseIndex.includes(1)
                          ? "phase-container-item-active"
                          : ""
                      }`}
                      onClick={() => this.openPhase(1)}
                    >
                      <div className="phase-container-item-2">
                        <div className="phase-container-item-index">
                          Stage 2
                        </div>
                        <div className="phase-container-item-title">
                          Software Integration
                        </div>
                      </div>
                      <div className="phase-container-item-content phase-container-item-content2">
                        <div>
                          Build and integrate software, incorporating blockchain
                          technology to connect smart devices and create a
                          seamless energy IoT platform.
                        </div>
                      </div>
                    </div>
                    <div
                      className={`phase-container-item ${
                        openPhaseIndex.includes(2)
                          ? "phase-container-item-active"
                          : ""
                      }`}
                      onClick={() => this.openPhase(2)}
                    >
                      <div className="phase-container-item-3">
                        <div className="phase-container-item-index">
                          Stage 3
                        </div>
                        <div className="phase-container-item-title">
                          Ecosystem Building
                        </div>
                      </div>
                      <div className="phase-container-item-content phase-container-item-content3">
                        <div>
                          Establish a decentralized, blockchain-based,
                          <br />
                          user-driven energy community through
                          <br />
                          partnerships and distributed energy resource
                          <br />
                          integration.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="homepage-floor">
            <div className="homepage-content3">
              <p className="content3-title">We are in a good business</p>
              <p className="content3-desc">
                By connecting the 3rd party energy devices, Starpower can:
                <br />
                ·coordinate larger number of energy devices.
                <br />
                ·generate application layer revenue and share with manufacturers
                & device users.
              </p>
              <div className="content3-block-title">MarketSize:</div>
              <div className="content3-block">
                <div className="content3-block-item">
                  <p>~50 billion</p>
                  <p>
                    Virtual <br />
                    Power Plant
                  </p>
                </div>
                <div className="content3-block-item">
                  <p>100 billion</p>
                  <p>
                    Carbon <br />
                    Credit
                  </p>
                </div>
                <div className="content3-block-item">
                  <p>3 T</p>
                  <p>
                    Electricity <br />
                    Market
                  </p>
                </div>
                <div className="content3-block-item">
                  <p>100 billion</p>
                  <p>
                    Software <br />
                    (Energy Data)
                  </p>
                </div>
              </div>
              <div className="content3-block-title">Networks:</div>
              <div className="content3-block">
                <div className="content3-block-item">
                  <p>27 M+ pcs</p>
                  <p>EV</p>
                </div>
                <div className="content3-block-item">
                  <p>13 M+ pcs</p>
                  <p>PV</p>
                </div>
                <div className="content3-block-item">
                  <p>2 billion pcs</p>
                  <p>Appliances</p>
                </div>
                <div className="content3-block-item">
                  <p>10 M+ pcs</p>
                  <p>Battery</p>
                </div>
              </div>
            </div>
          </div> */}
              {/* <div className="homepage-floor">
            <div className="homepage-content4">
              <p className="content4-title">Starpower Roadmap</p>
              <p className="content4-desc">
                Starpower leverages a combination of decentralized physical
                infrastructure networks (DePIN) and cutting-edge virtual power
                plant (VPP) technology. Our approach centers on integrating
                distributed energy resources (DERs) such as solar panels, wind
                turbines, and energy storage systems, to create a responsive and
                dynamic energy supply capable of meeting the variable demands of
                AI technologies.
              </p>
              <div className="content4-block">
                <div className="content4-block-item">
                  <img src={content4Img1} alt="" />
                  <p>
                    Use smart plugs as the hardware access points, with tokens
                    as incentives, to quickly aggregate a large number of
                    residential appliances.
                  </p>
                </div>
                <div className="content4-block-item">
                  <img src={content4Img2} alt="" />
                  <p>
                    Open the API interface to integrate energy storage
                    batteries, and establish standard data formats for
                    system-wide sharing based on the volume of users.{" "}
                  </p>
                </div>
                <div className="content4-block-item">
                  <img src={content4Img3} alt="" />
                  <p>
                    Open-source transmission protocols, making Starpower one of
                    the standards for building the energy internet.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
              <div className="homepage-floor homepage-floor4">
                <div className="homepage-content4">
                  <p className="content4-title">Together with</p>
                  {!isMobile ? (
                    <div className="brand-container">
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item1">
                          <img
                            src={require("@/assets/images/home/alliance.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item2">
                          <img
                            src={require("@/assets/images/home/arweave.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item3">
                          <img
                            src={require("@/assets/images/home/lotex.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item4">
                          <img
                            src={require("@/assets/images/home/basls.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item5">
                          <img
                            src={require("@/assets/images/home/redex.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item11">
                          <img
                            src={require("@/assets/images/home/solana.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item22">
                          <img
                            src={require("@/assets/images/home/helium.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item33">
                          <img
                            src={require("@/assets/images/home/hashkey.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item44">
                          <img
                            src={require("@/assets/images/home/fenbushi.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item55">
                          <img
                            src={require("@/assets/images/home/escape.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="brand-container">
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item1">
                          <img
                            src={require("@/assets/images/home/alliance.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item2">
                          <img
                            src={require("@/assets/images/home/arweave.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item3">
                          <img
                            src={require("@/assets/images/home/lotex.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item11">
                          <img
                            src={require("@/assets/images/home/basls.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item22">
                          <img
                            src={require("@/assets/images/home/redex.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item33">
                          <img
                            src={require("@/assets/images/home/solana.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item111">
                          <img
                            src={require("@/assets/images/home/helium.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item222">
                          <img
                            src={require("@/assets/images/home/hashkey.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="brand-container-row">
                        <div className="brand-container-row-item brand-container-row-item333">
                          <img
                            src={require("@/assets/images/home/fenbushi.png")}
                            alt=""
                          />
                        </div>
                        <div className="brand-container-row-item brand-container-row-item444">
                          <img
                            src={require("@/assets/images/home/escape.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="homepage-testimonials">
                <div className="homepage-testimonials-title">Testimonials</div>
                {/* <div className="homepage-testimonials-banner">
              <div className="homepage-testimonials-loop">
                <div>
                  <div className="homepage-testimonials-list">
                    {itemList.map((item, index) => {
                      return (
                        <div className="testimonials-list-item" key={index}>
                          <div className="testimonials-item-info">
                            {item.desc}
                          </div>
                          <div className="testimonials-item-person">
                            <img src={item.imgSrc} alt="" />
                            <div className="item-person-detail">
                              <p>{item.name}</p>
                              <p>{item.position}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div className="homepage-testimonials-list">
                    {itemList.map((item, index) => {
                      return (
                        <div className="testimonials-list-item" key={index}>
                          <div className="testimonials-item-info">
                            {item.desc}
                          </div>
                          <div className="testimonials-item-person">
                            <img src={item.imgSrc} alt="" />
                            <div className="item-person-detail">
                              <p>{item.name}</p>
                              <p>{item.position}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div> */}
                <div className="homepage-testimonials-banner">
                  <div>
                    <div className="homepage-testimonials-list">
                      {itemList.map((item, index) => {
                        return (
                          <div className="testimonials-list-item" key={index}>
                            <div className="testimonials-item-info">
                              {item.desc}
                            </div>
                            <div className="testimonials-item-person">
                              <img src={item.imgSrc} alt="" />
                              <div className="item-person-detail">
                                <p>{item.name}</p>
                                <p>{item.position}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="homepage-events">
            <div className="homepage-events-title">Events around the world</div>
            <div className="homepage-events-banner">
              <div className="homepage-events-loop">
                <div>
                  <div className="homepage-events-list">
                    {eventsList.map((item, index) => {
                      if (item.type == "blockTwo") {
                        if (item.itemsTypes == "top") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-top-top">
                                <p>{item.items[0].title}</p>
                                <p>{item.items[0].desc}</p>
                              </div>
                              <div className="blockTwo-top-bottom">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "down") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-down-top">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-down-down">
                                <p>{item.items[1].title}</p>
                                <p>{item.items[1].desc}</p>
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "img") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-img">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-img">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        }
                      } else if (item.type == "bigImg") {
                        return (
                          <div className="events-item-bigimg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      } else if (item.type == "halfImg") {
                        return (
                          <div className="events-item-halfImg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                <div>
                  <div className="homepage-events-list">
                    {eventsList.map((item, index) => {
                      if (item.type == "blockTwo") {
                        if (item.itemsTypes == "top") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-top-top">
                                <p>{item.items[0].title}</p>
                                <p>{item.items[0].desc}</p>
                              </div>
                              <div className="blockTwo-top-bottom">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "down") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-down-top">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-down-down">
                                <p>{item.items[1].title}</p>
                                <p>{item.items[1].desc}</p>
                              </div>
                            </div>
                          );
                        } else if (item.itemsTypes == "img") {
                          return (
                            <div className="events-item-blockTwo" key={index}>
                              <div className="blockTwo-img">
                                <img src={item.items[0].imgSrc} alt="" />
                              </div>
                              <div className="blockTwo-img">
                                <img src={item.items[1].imgSrc} alt="" />
                              </div>
                            </div>
                          );
                        }
                      } else if (item.type == "bigImg") {
                        return (
                          <div className="events-item-bigimg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      } else if (item.type == "halfImg") {
                        return (
                          <div className="events-item-halfImg" key={index}>
                            <img src={item.imgSrc} alt="" />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(Home);
