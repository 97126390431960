import React from "react";
import TweenOne from "rc-tween-one";
import { Menu, Modal, Icon, notification } from "antd";
import { getChildrenToRender } from "./utils";
import NFTapply from "./NFTapply";
import logoImg from "../../assets/images/logo-new.png";
import { Link, withRouter } from "react-router-dom";
import { enquireScreen } from "enquire-js";
import axios from "axios";
import "./less/header.less";
const bs58 = require("bs58");

const qrcode1 = require("../../assets/images/presale/Android.png");
const qrcode2 = require("../../assets/images/presale/AppleQR.png");
const qrcode3 = require("../../assets/images/presale/galaxy.png");
const phantomIcon = require("../../assets/images/wallet/phantom.png");
const okxIcon = require("../../assets/images/wallet/OKX.png");
let isMobile;
if (window.innerWidth < 1366) {
  isMobile = true;
} else {
  enquireScreen((b) => {
    isMobile = b;
  });
}
const { Item, SubMenu } = Menu;

const getProvider = () => {
  if ("phantom" in window) {
    const provider = window.phantom.solana;
    if (provider.isPhantom) {
      return provider;
    }
  }
  // window.open("https://phantom.app/", "_blank");
};

const getOkxProvider = () => {
  if (typeof window.okxwallet !== "undefined") {
    console.log("OKX is installed!");
    return window.okxwallet.solana;
  }
};

const addrSubStr = (strParam) => {
  return (
    strParam.slice(0, 6) +
    "..." +
    strParam.slice(strParam.length - 4, strParam.length)
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      phoneOpen: false,
      menuHeight: 0,
      openKeys: [],
      isPageLoading: false,
      headerStyle: "header-home",
      showNFT: false,
      downloadVisible: false,
      downloadTitle: "Android",
      walletVisible: false,
      pageY: 0,
      solAddress: "",
      phantomInstall: false,
      okxInstall: false,
      dSource: this.props.dataSource,
      walletStep: 1,
      showWalletType: 1, // 1|phantom 2|okx
    };
  }
  componentDidMount() {
    // this.props.eventBus.on('animationLoaded', () => {
    //   this.setState({
    //     show: true
    //   })
    // });
    const phantomProvide = getProvider();
    const okxProvider = getOkxProvider();
    if (phantomProvide) {
      this.setState({
        phantomInstall: true,
      });
    } else {
      this.setState({
        phantomInstall: false,
      });
    }
    if (okxProvider) {
      this.setState({
        okxInstall: true,
      });
    } else {
      this.setState({
        okxInstall: false,
      });
    }
    localStorage.setItem("notConnectWallet", true)
    window.addEventListener("click", this.onStorageChange);
    this.props.dataSource.Menu.children.splice(1, 0, {
      name: "item-Document",
      className: "header0-item lvu09ws29k-editor_css",
      children: {
        href: "https://starpower.gitbook.io/starpower-lite-paper",
        target: "_blank",
        children: [
          {
            children: (
              <span>
                <p>Lite Paper</p>
              </span>
            ),
            name: "text",
            className: "lvu08ulsiw-editor_css",
          },
        ],
      },
    });
    this.setState({
      show: true,
    });
    let solAddr = localStorage.getItem("solanaRegAddress");
    let dSource = this.state.dSource;
    if (solAddr) {
      dSource.Menu.children.push({
        name: "item-wallet",
        className: "header0-item lvu09ws29k-editor_css",
        children: {
          href: "/wallet",
          children: [
            {
              children: (
                <span>
                  <p>My Profile</p>
                </span>
              ),
              name: "text",
              className: "lvu08ulsiw-editor_css",
            },
          ],
        },
      });
      this.setState({
        solAddress: solAddr,
        dSource: dSource,
      });
    }
    if (this.state.show) {
      let header = document.getElementById("headerDom");
      let headerMsg = document.getElementById("headerMessage");
      // let that = this
      if (isMobile) {
        header.style.position = "fixed";
        header.style.zIndex = "99";
        // window.addEventListener("touchmove", function (ev) {
        //   let evPageY = ev.touches[0].pageY
        //   if (evPageY - that.state.pageY >= 0) {
        //     header.style.position = "relative";
        //   } else {
        //     header.style.position = "fixed";
        //   }
        //   that.setState({
        //     pageY: evPageY
        //   })
        // });
      } else {
        window.addEventListener("wheel", function (ev) {
          if (ev.deltaY > 0) {
            // header.style.position = "relative";
            header.style.position = "fixed";
            header.style.opacity="0"
          } else {
            header.style.position = "fixed";
            header.style.opacity="1"
            header.style.zIndex = "99";
          }
        });
      }
    }
  }

  componentWillUnmount() {
    // this.props.eventBus.off('animationLoaded');
    window.removeEventListener("click", this.onStorageChange);
  }
  onStorageChange = () => {
    if (localStorage.getItem("notConnectWallet")) {
      this.setState({
        walletVisible: JSON.parse(localStorage.getItem("walletVisible")),
      });
    } else {
      this.setState({
        walletVisible: false
      })
    }
  };
  showNFTfunc = () => {
    this.setState({
      showNFT: true,
    });
  };
  closeNFTfunc = (value) => {
    this.setState({
      showNFT: value,
    });
  };
  openDownload = () => {
    this.setState({
      downloadVisible: true,
      downloadTitle: "Starpower",
    });
  };
  cancelDownload() {
    this.setState({
      downloadVisible: false,
    });
  }
  cancelApply() {
    this.setState({
      showNFT: false,
    });
  }
  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
      isPageLoading: true,
    });
  };

  onOpenChange = (openKeys) => {
    this.setState({
      openKeys: openKeys.slice(-1),
    });
  };
  openWalletList = () => {
    if (localStorage.getItem("solanaAddress")) {
      localStorage.setItem("walletVisible", false);
      window.location.reload();
      localStorage.setItem("solanaAddress", "");
      localStorage.setItem('discordRoleInfo', '');
      localStorage.setItem('joinDiscord', false)
      localStorage.setItem("notConnectWallet", true);
      localStorage.setItem("twitterAuthStatus", false);
      localStorage.setItem("discordAuthStatus", false);
      return;
    }
    localStorage.setItem("walletVisible", true);
  };
  // connect wallet
  cWallet = async (walletType) => {
    const phantomProvide = getProvider();
    const okxProvider = getOkxProvider();
    let provider = {};
    if (walletType == "phantom") {
      if (phantomProvide) {
        provider = phantomProvide; // see "Detecting the Provider"
      } else {
        this.setState({
          walletStep: 2,
          showWalletType: 1,
        });
      }
    } else {
      if (okxProvider) {
        provider = okxProvider;
      } else {
        this.setState({
          walletStep: 2,
          showWalletType: 2,
        });
      }
    }
    try {
      if (provider) {
        const resp = await provider.connect();
        const message = new Date().getTime().toString();
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await provider.signMessage(
          encodedMessage,
          "utf8"
        );
        if (signedMessage.signature) {
          const base58String = bs58.encode(signedMessage.signature);
          let params = {
            solAddress: resp.publicKey.toString(),
            signature: base58String,
            timestamp: message,
          };
          axios({
            url: "/refer/api/linkWallet",
            method: "post",
            data: params,
            headers: {
              // "Content-Type": "multipart/form-data", //Content-Type form
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              localStorage.setItem("solanaAddress", resp.publicKey.toString());
              localStorage.setItem(
                "solanaRegAddress",
                resp.publicKey.toString()
              );
              localStorage.setItem("notConnectWallet", false);
              localStorage.setItem("walletVisible", false);
              let submitRef = localStorage.getItem('submitRef') || false;
              if (JSON.parse(submitRef)) {
                let params = {
                  solAddress: resp.publicKey.toString(),
                  signature: base58String,
                  timestamp: message,
                  code: localStorage.getItem('refCode')
                }
                axios({
                  url: "/refer/api/inviteRelation",
                  method: "post",
                  data: params,
                  headers: {
                    // "Content-Type": "multipart/form-data", //Content-Type form
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    let resData = res.data;
                    if (resData.code == "200") {
                      localStorage.setItem("solanaAddress", resp.publicKey.toString());
                      localStorage.setItem(
                        "solanaRegAddress",
                        resp.publicKey.toString()
                      );
                      notification.success({
                        top: 70,
                        duration: 2,
                        placement: "bottomLeft",
                        message: "Success",
                        description: "Submitted successfully!",
                        onClick: () => {
                          console.log("close");
                        },
                      });
                      window.location.reload();
                    } else {
                      notification.error({
                        top: 70,
                        duration: 2,
                        placement: "bottomLeft",
                        message: "Error",
                        description: resData.msg,
                        onClick: () => {
                          console.log("close");
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                  localStorage.setItem('submitRef', false);
                  this.onStorageChange();
                  return;
              }
              let dSource = this.state.dSource;
              dSource.Menu.children.push({
                name: "item-wallet",
                className: "header0-item lvu09ws29k-editor_css",
                children: {
                  href: "/wallet",
                  children: [
                    {
                      children: (
                        <span>
                          <p>My Profile</p>
                        </span>
                      ),
                      name: "text",
                      className: "lvu08ulsiw-editor_css",
                    },
                  ],
                },
              });
              this.setState({
                solAddress: resp.publicKey.toString(),
                dSource: dSource,
                walletVisible: false,
              });
              if (this.props.location.pathname == "/wallet") {
                window.location.reload();
              } else {
                this.props.history.replace("/wallet");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } catch (err) {
      // { code: 4001, message: 'User rejected the request.' }
    }
  };

  render() {
    const { isMobile, ...props } = this.props;
    const { show, phoneOpen, dSource } = this.state;
    let navData = dSource.Menu.children;
    // Header排除的菜单
    navData = navData.filter(
      (item) => !["item-Legal", "item-Company"].includes(item.name)
    );
    let productMenuIndex = navData.findIndex((item) => item.name === "item1");
    navData[productMenuIndex] = {
      ...navData[productMenuIndex],
      subItem: null,
    };
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header0-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header0-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return show ? (
      <div id="Nav0_0">
        <a
          href="https://starpower-market.myshopify.com/products/starplug"
          target="_blank"
        >
          <div className="header-message" id="headerMessage">
            Starplug is Now Available for Order!
            <button>BUY NOW</button>
          </div>
        </a>
        <TweenOne
          component="header"
          animation={{ opacity: 0, type: "from" }}
          {...dSource.wrapper}
          {...props}
          id="headerDom"
        >
          <div
            {...dSource.page}
            className={`${dSource.page.className}${phoneOpen ? " open" : ""}`}
          >
            <TweenOne
              animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
              {...dSource.logo}
            >
              <Link to={dSource.logo.href}>
                <img width="100%" src={dSource.logo.children} alt="img" />
              </Link>
            </TweenOne>
            {isMobile && (
              <div
                {...dSource.mobileMenu}
                onClick={() => {
                  this.phoneClick();
                }}
              >
                <em />
                <em />
                <em />
              </div>
            )}
            <TweenOne
              {...dSource.Menu}
              animation={
                isMobile
                  ? {
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = "auto";
                        }
                      },
                      ease: "easeInOutQuad",
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu
                mode={isMobile ? "inline" : "horizontal"}
                defaultSelectedKeys={["sub0"]}
                theme="dark"
                // subMenuCloseDelay={3600}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
              >
                {navChildren}
              </Menu>
              {isMobile && (
                <div className={"moreBtn"}>
                  <button className="shopBtn" onClick={this.showNFTfunc}>
                    NFT activation
                  </button>
                  <button
                    className="shopBtn"
                    onClick={() => {
                      this.openWalletList();
                    }}
                  >
                    Connect Wallet
                  </button>
                </div>
              )}
            </TweenOne>
            {!isMobile && (
              <div className={"moreBtn"}>
                <button className="shopBtn" onClick={this.showNFTfunc}>
                  NFT activation
                </button>
                <button
                  className={isMobile ? "hidden" : "shopBtn"}
                  onClick={() => {
                    this.openWalletList();
                  }}
                >
                  {localStorage.getItem("solanaAddress") &&
                  localStorage.getItem("solanaAddress").length > 0 ? (
                    <span>
                      {addrSubStr(localStorage.getItem("solanaAddress"))}
                      &nbsp;&nbsp;
                      <Icon type="disconnect" />
                    </span>
                  ) : (
                    "Connect Wallet"
                  )}
                </button>
              </div>
            )}
          </div>
        </TweenOne>
        <Modal
          mask={true}
          maskStyle={{ backgroundColor: "#000", opacity: "0.9" }}
          title="Get NFT Rewards"
          centered
          closable={false}
          footer={null}
          className="NFT-apply"
          destroyOnClose={true}
          maskClosable={true}
          onCancel={() => this.cancelApply(false)}
          visible={this.state.showNFT}
        >
          <NFTapply closeNFTfunc={this.closeNFTfunc} />
        </Modal>
        <Modal
          title=""
          width={isMobile ? "" : "50%"}
          centered
          mask={true}
          maskStyle={{ backgroundColor: "#000", opacity: "0.9" }}
          footer={null}
          visible={this.state.downloadVisible}
          onCancel={() => this.cancelDownload(false)}
          className="download-modal"
        >
          <div className="download-modal-content">
            <p>
              Download for{" "}
              <span className="download-modal-title">
                {this.state.downloadTitle}
              </span>{" "}
              mobile
            </p>
            <div className="download-qrcode-list">
              <div className="download-qrcode-item">
                <img className="download-modal-qrcode" src={qrcode2} alt="" />
                <p>iOS</p>
              </div>
              <div className="download-qrcode-item">
                <img className="download-modal-qrcode" src={qrcode1} alt="" />
                <p>Android</p>
              </div>
              <div className="download-qrcode-item">
                <img className="download-modal-qrcode" src={qrcode3} alt="" />
                <p>Galaxystore</p>
              </div>
            </div>
            <p style={{ fontWeight: "bold" }}>Your new trusted companion</p>
            <p>
              Scan the QR code or search for Starpower in the App Store or
              Google Play soon.
            </p>
          </div>
        </Modal>
        <Modal
          title="Connect a Wallet"
          width={isMobile ? "" : "400px"}
          centered
          mask={true}
          maskStyle={{ backgroundColor: "#000", opacity: "0.9" }}
          maskClosable={true}
          footer={null}
          visible={this.state.walletVisible}
          onCancel={() => localStorage.setItem("walletVisible", false)}
          className="wallet-modal"
        >
          {this.state.walletStep == 1 ? (
            <div className="wallet-modal-body">
              <button
                className="wallet-btn"
                onClick={() => this.cWallet("phantom")}
              >
                <span>
                  <img src={phantomIcon} alt="" />
                  Phantom
                </span>
                {this.state.phantomInstall ? (
                  <span>Installed</span>
                ) : (
                  <span></span>
                )}
              </button>
              <button
                className="wallet-btn"
                onClick={() => this.cWallet("okx")}
              >
                <span>
                  <img src={okxIcon} alt="" />
                  OKX Wallet
                </span>
                {this.state.okxInstall ? <span>Installed</span> : <span></span>}
              </button>
            </div>
          ) : (
            <div className="wallet-modal-body">
              <button
                className="wallet-btn"
                onClick={() =>
                  this.state.showWalletType == 1
                    ? window.open("https://phantom.app/", "_blank")
                    : window.open("https://www.okx.com/download", "_blank")
                }
              >
                <span>
                  <img
                    src={this.state.showWalletType == 1 ? phantomIcon : okxIcon}
                    alt=""
                  />
                  Install Chrome extension
                </span>
              </button>
            </div>
          )}
        </Modal>
      </div>
    ) : null;
  }
}

export default withRouter(Header);
